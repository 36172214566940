<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md2
                >
                  <v-menu
                    ref="menu"
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="startDateFormatted"
                        :label="$t('evoucher.issuance.fromDate')"
                        persistent-hint
                        @blur="date = parseDate(startDateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      :min="minStartDate"
                      no-title
                      @input="menuStartDate = false"/>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md2>
                  <v-menu
                    ref="menu"
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="endDateFormatted"
                        :label="$t('evoucher.issuance.toDate')"
                        persistent-hint
                        @blur="date = parseDate(endDateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      :min="minEndDate"
                      no-title
                      @input="menuEndDate = false"/>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="getVouchersReconciliationHistories()()"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <!-- Receive list -->
        <material-card
          :title="$t('voucherReconciliation.history.title')"
          color="green"
          flat
          full-width
        >
          <div @mouseleave="$refs.tblHistoryList.expanded=[]">
            <v-data-table
              ref="tblHistoryList"
              :headers="voucherInfoHeaders"
              :items="voucherInfoList"
              :no-data-text="$t('common.noDataAvailable')"
              :expand="false"
              item-key="stt"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="text--darken-3 font-medium"
                  v-text="$t(header.text)"
                />
              </template>
              <template v-slot:items="props">
                <tr
                  @click="props.expanded=!props.expanded"
                  @mouseover="props.expanded=true">
                  <td
                    v-for="(header, index) in voucherInfoHeaders"
                    :key="header.value + index">
                    <span v-if="header.value == 'checkCode'">
                      {{ props.item[header.value] }}
                    </span>
                    <span v-else-if="header.value == 'totalPrice'">
                      {{ formatMoney(props.item[header.value]) }}
                    </span>
                    <span
                      v-else-if="header.value == 'status'"
                      :style="`color: ${getColor(props.item[header.value])};`">
                      {{ $t(getStatus(props.item[header.value])) }}
                    </span>
                    <span v-else>{{ props.item[header.value] }}</span>
                  </td>
                </tr>
              </template>
              <template v-slot:expand="props">
                <div
                  class="py-2 mx-3"
                  style="display: flex; white-space: nowrap; padding-left: 55px"
                  @mouseleave="props.expanded=false">
                  <action-item
                    first
                    class="text-success"
                    @click="viewDetail(props.item)">
                    {{ $t('voucherReconciliation.action.view') }}
                  </action-item>
                </div>
              </template>
            </v-data-table>
          </div>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <eVoucherReconciliationDetailModal
      ref="eVoucherReconciliationDetailModal" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EVoucherReconciliationDetailModal from '../Modal/EVoucherReconciliationDetailModal.vue'
import dateUtils from 'utils/dateUtils'
import VoucherReconciliationStatus from 'enum/voucherReconciliationStatus'
import functionUtils from 'utils/functionUtils'
import moment from 'moment'
import VoucherReconciliationHistoryType from 'enum/voucherReconciliationHistoryType'
import ActionItem from 'Components/ActionItem.vue'
export default {
  components: {
    EVoucherReconciliationDetailModal,
    ActionItem
  },
  data: () => ({
    voucherInfoHeaders: [
      { text: 'common.nonumber', value: 'stt' },
      { text: 'voucherReconciliation.code', value: 'checkCode' },
      { text: 'voucherReconciliation.createdDate', value: 'createdDate' },
      { text: 'voucherReconciliation.supplier', value: 'supplier' },
      { text: 'voucherReconciliation.usedSite', value: 'usedSite' },
      { text: 'voucherReconciliation.userCreated', value: 'userCreated' },
      { text: 'voucherReconciliation.totalVoucher', value: 'totalVoucher' },
      { text: 'voucherReconciliation.totalPrice', value: 'totalPrice' },
      { text: 'voucherReconciliation.status.action', value: 'status' }
    ],
    voucherInfoList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    isLoading: false,
    startDate: null,
    startDateFormatted: null,
    menuStartDate: false,
    minStartDate: null,
    endDate: null,
    endDateFormatted: null,
    menuEndDate: false,
    minEndDate: null
  }),
  computed: {
    ...mapGetters([
      'VOUCHERS_CHECK_HISTORIES_DATA',
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    startDate (val) {
      this.startDateFormatted = this.formatDate(this.startDate)
      if (this.startDate >= this.endDate) {
        this.endDate = moment(this.startDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
      }
      this.minEndDate = moment(this.startDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
    },
    endDate (val) {
      this.endDateFormatted = this.formatDate(this.endDate)
    },
    VOUCHERS_CHECK_HISTORIES_DATA () {
      let res = this.VOUCHERS_CHECK_HISTORIES_DATA
      //   Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      //   Handle response data
      let voucherData = res.results
      this.voucherInfoList = []
      for (let i = 0, size = voucherData.length; i < size; i++) {
        let obj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: voucherData[i].reconciliation_info.id,
          checkCode: voucherData[i].reconciliation_info.code,
          createdDate: dateUtils.formatBeautyDate(voucherData[i].reconciliation_info.date_created),
          totalVoucher: voucherData[i].reconciliation_info.total_voucher,
          totalPrice: voucherData[i].reconciliation_info.total_price,
          status: voucherData[i].reconciliation_info.status,
          supplierId: voucherData[i].history_info.supplier_id,
          supplier: voucherData[i].history_info.supplier,
          usedSite: voucherData[i].history_info.used_site,
          userCreated: voucherData[i].history_info.user_created
        }
        this.voucherInfoList.push(obj)
      }
    }
  },
  created () {
    this.getVouchersReconciliationHistories()
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Get vouchers check histories
     */
    getVouchersReconciliationHistories: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage,
          startDate: this.startDate,
          endDate: this.endDate
        }
      }
      this.isLoading = true
      this.GET_VOUCHER_RECONCILIATION_HISTORIES(filter).then(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getVouchersReconciliationHistories()
    },
    /**
     * View detail
     */
    viewDetail: function (item) {
      let fromHistory = true
      this.$refs.eVoucherReconciliationDetailModal.onShowModal(item, fromHistory)
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    /**
     * Get status
     */
    getStatus: function (status) {
      if (VoucherReconciliationHistoryType.SENT === status) {
        return 'voucherReconciliation.action.submit'
      } else if (VoucherReconciliationHistoryType.CREATED === status) {
        return 'voucherReconciliation.action.create'
      } else if (VoucherReconciliationHistoryType.APPROVED === status) {
        return 'voucherReconciliation.action.approve'
      } else if (VoucherReconciliationHistoryType.REJECTED === status) {
        return 'voucherReconciliation.action.cancel'
      } else {
        return 'voucherReconciliation.action.delete'
      }
    },
    /**
     * Get color
     */
    getColor: function (status) {
      if (VoucherReconciliationStatus.NOT_REQUEST_YET === status) {
        return 'blue'
      } else if (VoucherReconciliationStatus.PREPARE_FOR_SUBMIT === status) {
        return 'blue'
      } else if (VoucherReconciliationStatus.WAIT_FOR_APPROVAL === status) {
        return 'green'
      } else if (VoucherReconciliationStatus.APPROVED === status) {
        return 'green'
      } else {
        return 'red'
      }
    },
    ...mapActions([
      'GET_VOUCHER_RECONCILIATION_HISTORIES'
    ])
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
    cursor: pointer;
}
</style>
